<template>
    <ContentLoader secondaryColor="#ccd8ff" primaryColor="#e7ecff" background="#fff">
        <rect x="0" y="0" rx="4" ry="4" width="100" height="8" />
        <rect x="280" y="0" rx="5" ry="5" width="41" height="10" />
        <rect x="350" y="0" rx="5" ry="5" width="41" height="10" />
        <rect x="0" y="15" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="24" rx="3" ry="3" width="80" height="6" />
        <rect x="0" y="32" rx="2" ry="2" width="130" height="4" />
        <rect x="280" y="24" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="24" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="44" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="53" rx="3" ry="3" width="70" height="6" />
        <rect x="0" y="61" rx="2" ry="2" width="135" height="4" />
        <rect x="280" y="53" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="53" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="74" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="83" rx="3" ry="3" width="90" height="6" />
        <rect x="0" y="91" rx="2" ry="2" width="154" height="4" />
        <rect x="280" y="83" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="83" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="104" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="113" rx="3" ry="3" width="105" height="6" />
        <rect x="0" y="121" rx="2" ry="2" width="130" height="4" />
        <rect x="280" y="113" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="113" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="134" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="143" rx="3" ry="3" width="65" height="6" />
        <rect x="0" y="151" rx="2" ry="2" width="140" height="4" />
        <rect x="280" y="143" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="143" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="164" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="173" rx="3" ry="3" width="82" height="6" />
        <rect x="0" y="181" rx="2" ry="2" width="110" height="4" />
        <rect x="280" y="173" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="173" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="194" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="203" rx="3" ry="3" width="78" height="6" />
        <rect x="0" y="211" rx="2" ry="2" width="160" height="4" />
        <rect x="280" y="203" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="203" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="224" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="233" rx="3" ry="3" width="55" height="6" />
        <rect x="0" y="241" rx="2" ry="2" width="140" height="4" />
        <rect x="280" y="233" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="233" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="224" rx="0" ry="0" width="400" height=".5" />

        <rect x="0" y="263" rx="3" ry="3" width="85" height="6" />
        <rect x="0" y="271" rx="2" ry="2" width="180" height="4" />
        <rect x="280" y="263" rx="5" ry="5" width="30" height="10" />
        <rect x="350" y="263" rx="5" ry="5" width="30" height="10" />
        <rect x="0" y="254" rx="0" ry="0" width="400" height=".5" />

    </ContentLoader>
</template>
<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
